.demo-container {
    /* position: fixed; */
    /* top: 50%; */
    /* transform: translate(0, -50%); */
    display: flex; 
    flex-direction: column;
    overflow-y:auto;
    display: table;
    margin: 0 auto;
}

.formgrid{
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-row-gap: 10px;
}