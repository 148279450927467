.header-div {
    margin: 10px;
}
.chat-room-container {
    /* position: fixed; */
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    margin: 20px;
    margin-left: 60px;
    margin-right: 60px;
}

.card-description { 
    width: 200px;
  }
  

.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
}

.user-card-view {
    flex: 1;
    flex-direction: column;
}

.curr-round-card-holder {
    border: black 1px solid;
    border-radius: 5px;
    padding: 10px;
    margin-left: 10px;
}

.title {
    margin: 20px;
}


.messages-container {
    /* flex: 1; */
    min-height: 100%;
    width: 25%;
    overflow: scroll;
    border: 1px solid black;
    border-radius: 7px 7px 0 0;
}

.gamedescription-button {
    width: 70px;
    padding: 12px 12px;
    font-size: 20px;
    background: #31a24c;
    color: white;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    border-radius: 7px;
    margin-left: 10px;
}

.messages-list {
    list-style-type: none;
    padding: 0;
}

.hor-messages-list div {
    display: inline-block;
}

.new-message-input-field {
    height: 200px;
    max-height: 50%;
    font-size: 20px;
    padding: 8px 12px;
    resize: none;
}

.message-item {
    width: 80%;
    margin: 8px;
    padding: 12px 8px;
    word-break: break-word;
    border-radius: 4px;
    color: white;
}

.message-item-square {
    width: 150px;
    height: 150px;
    margin: 8px;
    padding: 10px 10px 10px 10px;
    word-break: break-word;
    border-radius: 4px;
    color: white;
}

.my-message {
    background-color: rgb(0, 132, 255);
    margin: 10px;
    padding: 10px 10px 10px 10px;
    width: 220px;
    height: 200px;
}

.received-message {
    background-color: #3f4042;
    margin-right: auto;
    width: 220px;
    height: 200px;
}

.send-message-button {
    font-size: 16px;
    font-weight: 300;
    color: white;
    background: #31a24c;
    text-align: center;
    padding: 24px 12px;
    min-width: 200px;
    min-height: 200px;
    margin: 5px;
}

.messages-container,
.new-message-input-field,
.send-message-button {
    border-color: black;
}

.card-container {
    margin-left: 5%;
}

.member-lobby {
    /* left: 50%; */
    margin: 0 auto;
    padding-top: 10%;
    width: 30%;
    /* width: 500px; */
}
