.message-item-square {
    width: 150px;
    height: 150px;
    margin: 8px;
    padding: 10px 10px 10px 10px;
    word-break: break-word;
    border-radius: 4px;
    color: white;
    position: relative;
    z-index: -1;
}

.my-message {
    background-color: rgb(0, 132, 255);
    margin: 10px;
    padding: 10px 10px 10px 10px;
    width: 200px;
    height: 200px;
}

.received-message {
    background-color: #3f4042;
    margin-right: auto;
    width: 200px;
    height: 200px;
}

.average-text { 
    position: absolute;
    bottom: 10px;
    left: 10px;
    font-size: 14px;
    color: #fff;
}

.card-description { /* Preserve line breaks */
    /* Add any other desired styling */
    width: 200px;
    height: 200px;
    font-size: 10px;
  }