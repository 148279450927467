.home-container {
    position: fixed;
    left: 30%;
    right: 30%;
    top: 50%;
    width: 40%;
    /* transform: translate(0, -50%); */
    display: flex;
    flex-direction: column;
}

.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
}

.box {
    position: relative;
    width: 70%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
}
