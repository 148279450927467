.home-container {
    position: fixed;
    left: 30%;
    right: 30%;
    top: 50%;
    width: 40%;
    display: flex;
    flex-direction: column;
}

.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
}

.box {
    position: relative;
    width: 80%;
    margin: 0 auto;
    height: auto;
    max-height: 80vh;
    margin-top: calc(100vh - 90vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
}

.center-div {
    display: flex;
    justify-content: center;
    margin: 20px;
}

.card-container {
    margin-left: 5%;
}

.card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Adjust the column width as needed */
    gap: 20px; 
  }

.send-message-button {
    font-size: 12px;
    font-weight: 300;
    color: white;
    background: #31a24c;
    padding: 24px 12px;
    min-width: 300px;
    min-height: 200px;
    margin: 5px;
}

.send-message-button {
    border-color: black;
}